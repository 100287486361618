<template>
  <!--  노션 링크 : https://www.notion.so/InfoguidancePage-7733b7e89dd44cee9e0c1193a6f3e794 -->
  <div>
    <div>
      <breadcrumb :breadcrumb_link="introduce_breadcrumb" />
    </div>
    <div class="header">
      <!--      <h1 class="overlay">인재채용</h1>-->
      <div class="headerimage" />
    </div>

    <div class="container">
      <div class="row d-flex justify-content-center">
        <div class="operationguide_body_hedaer col-md-12 mt-5 mb-4">
          <h2 class="text-center"><strong>인재채용</strong></h2>
        </div>
      </div>

      <div class="web-nav">
        <div id="btn-radios-1" class="d-flex justify-content-center categoryRadioGroup">
          <label
            v-for="(category, index) in categories"
            :key="index"
            class="cateBoxContainer"
            :class="{checked: selectedCategory === category.value}"
          >
            <input type="radio" :value="category.value" v-model="selectedCategory" />
            <router-link :to="category.to">
              <div class="cateBox">
                <span>{{ category.label }}</span>
              </div>
            </router-link>
          </label>
        </div>
      </div>
      <div class="mobile-nav mx-auto">
        <div id="btn-radios-2" class="categoryRadioGroup">
          <label
            v-for="(category, index) in categories"
            :key="index"
            class="cateBoxContainer"
            :class="{checked: selectedCategory === category.value}"
          >
            <input type="radio" :value="category.value" v-model="selectedCategory" />
            <router-link :to="category.to">
              <div class="cateBox">
                <span>{{ category.label }}</span>
              </div>
            </router-link>
          </label>
        </div>
      </div>

      <div style="min-height: 35rem" class="">
        <div class="recruit_title">
          <span>인재상</span>
          <div style="margin-top: 2rem"></div>
          <div class="recruit_title2">
            SK핀크스에서 원하는 인재상은 Global Mind에 기반한 핵심가치 실현으로 리조트 업계를 선도하는 Leading Company로
            성장하는데 중추적인 역할을 수행할 수 있는 인재입니다.
          </div>
        </div>

        <div style="margin-top: 6rem"></div>

        <div class="web">
          <div v-for="(item, index) in items" :key="index" class="contents">
            <template v-if="index % 2 !== 1">
              <div class="innerImg"><img :src="item.image" /></div>
              <div class="rowSpace"></div>
              <div class="innerText">
                <div class="tHeader text-left">{{ item.title }}</div>
                <div class="colSpace"></div>
                <div class="tBody text-left">
                  {{ item.desc }}
                </div>
              </div>
            </template>

            <template v-else>
              <div class="innerText">
                <div class="tHeader text-right">{{ item.title }}</div>
                <div class="colSpace"></div>
                <div class="tBody text-right">
                  {{ item.desc }}
                </div>
              </div>
              <div class="innerImg"><img :src="item.image" /></div>
            </template>
          </div>
        </div>
        <div class="mobile">
          <div v-for="(item, index) in items" :key="index" class="contents">
            <template>
              <div class="innerImg"><img :src="item.image" /></div>
              <div class="rowSpace"></div>
              <div class="innerText">
                <div class="tHeader text-left">{{ item.title }}</div>
                <div class="tBody text-left">
                  {{ item.desc }}
                </div>
              </div>
              <div class="rowSpace"></div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';

export default {
  name: 'HrFaq',
  data() {
    return {
      introduce_breadcrumb: {
        toplink: 'HOME',
        prevlink: '인재채용',
        present: '인재상',
      },
      items: [
        {
          image: require('@/assets/images/main/recruit_cont06_img01.png'),
          title: 'SERVICE ORIENTED',
          desc: '자신의 고객은 누구이며, 그들의 요구가 무엇인가에 대해 끊임 없이 묻고 답하며, 고객에게 만족 그 이상의 가치를 제공하는 것을 말합니다.',
        },
        {
          image: require('@/assets/images/main/recruit_cont06_img02.png'),
          title: 'PROFESSIONAL',
          desc: 'SK핀크스에 입사를 희망하는 사람은 자신의 직무에 대한 끊임 없는 연구와 학습을 통해 ‘세계 최고 전문가가 되겠다’는 목표와 ‘프로 근성’을 가져야 합니다.',
        },
        {
          image: require('@/assets/images/main/recruit_cont06_img03.png'),
          title: 'ACTION',
          desc: '바른 인성을 통해 타인과의 협조와 단합을 이끌어내며 책임감과 자율성을 바탕으로 조직구성원으로서의 역할과 의무를 충실히 수행하는 것을 말합니다.',
        },
        {
          image: require('@/assets/images/main/recruit_cont06_img04.png'),
          title: 'CREATION',
          desc: '창의적인 사고와 문제의식에 근간하여 현실에 안주하지 않고 더 높은 목표를 이루기 위해 노력하는 것을 말합니다. 無에서 有를 창조할 수 있다는 패기와 열정이 필요합니다.',
        },
      ],
      selectedCategory: '6',
      categories: [
        {value: '1', to: '/p/HrAbout', label: '채용안내'},
        {value: '2', to: '/p/HrNotice', label: '채용공고'},
        {value: '3', to: '/p/HrNoticeResult', label: '채용공고결과'},
        {value: '4', to: '/p/HrDbReg', label: '인재DB등록'},
        {value: '5', to: '/p/HrFaq', label: 'FAQ'},
        {value: '6', to: '/p/HrWant', label: '인재상'},
        {value: '7', to: '/p/HrWalfare', label: '복리후생'},
        {value: '8', to: '/p/Notice', label: '공지사항'},
        {value: '9', to: '/p/HrPolicy', label: '개인정보 처리방침'},
      ],
    };
  },
  mounted() {
    this.$store.dispatch('Display/changeName', {name: '인재상'});
  },
  components: {
    Breadcrumb,
  },
};
</script>

<style scoped lang="scss">
.itemContainer {
  margin-top: 2rem;
  margin-bottom: 1rem;
  line-height: 1rem;
}
.rowSpace {
  height: 1rem;
}
.colSpace {
  width: 0.5rem;
}
.optionText {
  font-size: 0.75rem;
  color: #717171;
}
.categoryRadioGroup {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0px;
}

.cateBoxContainer.checked .cateBox {
  background-color: #343a40;
}

.cateBoxContainer.checked .cateBox span {
  color: white;
}
input[type='radio'] {
  display: none;
}

.cateBox {
  background-color: white;
  border: 1px solid #b7b7b7;
  width: 100%;
  height: 3rem;
  text-align: center;
  cursor: pointer;
  font-weight: 900;
  line-height: 3rem;
}

.cateBox span {
  font-size: 0.9rem;
  user-select: none;
  color: #2f2f2f;
}

.recruit_title {
  margin-top: 2rem;
  margin-bottom: 3rem;
  font-size: 1.5rem;
  text-align: center;
}

.tHeader {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
.tBody {
  line-height: 1.2rem;
  font-size: 0.9rem;
}
.headerimage {
  width: 100%;
  margin: 0;
  content: url('~@/assets/images/main/bg_recruit.png');
}
.header {
  position: relative;
  width: 100%;
  > img {
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .web-nav {
    display: table;
    width: 100%;
  }
  .mobile-nav {
    display: none;
  }
  .cateBoxContainer {
    width: 100%;
  }
}
@media (max-width: 1200px) {
  .web-nav {
    display: none;
  }
  .mobile-nav {
    display: table;
  }
  .cateBoxContainer {
    width: 33%;
  }
}
@media (min-width: 992px) {
  .web {
    display: block;
  }
  .mobile {
    display: none;
  }
  .contents {
    display: flex;
    margin-bottom: 1rem;
  }
  .innerImg {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
    }
  }
  .innerText {
    width: 100%;
    padding: 1rem;
    align-self: center;
  }
  .recruit_title2 {
    font-size: 0.9rem;
    text-align: center;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 992px) {
  .web {
    display: none;
  }
  .mobile {
    display: block;
  }
  .contents {
    display: block;
    border: 1px solid #efefef;
    padding: 1rem;
    margin-bottom: 1rem;
  }

  .innerImg {
    img {
      width: 100%;
    }
  }
  .innerText {
    width: 100%;
  }
  .headerimage {
    display: none;
  }

  .header {
    position: relative;
    width: 100%;
    padding-top: 5rem;
    > img {
      width: 100%;
    }
  }
  .recruit_title2 {
    font-size: 0.9rem;
    text-align: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
